<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-11-20 15:11:01
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-20 16:04:40
 * @FilePath: \骏科官网\src\views\upgrade.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="upgrade">
        <!-- 升级维修主页面 -->
        <div class="upgrade-top">
            <div class="upgradTop-nav">
                <div class="upgradTop-nav-logo">
                    <img src="../../public/images/首页/logo.png" alt="">
                </div>
                <div class="upgradTop-nav-menu">
                    <div class="upgradTop-nav-menuItem" :class="{ menuActive: item.id == activeId }"
                        v-for="item in menuList" :key="item.id" @click="munuClick(item)">{{
                            item.title }} </div>
                </div>
            </div>
        </div>
        <div class="upgrade-main" ref="upgradeMainRef">
            <div class="upgradeMain-box">
                <img src="../../public/images/首页/img01.png" alt="">
                <div class="upgradeMain-box-info">网站升级维护中，敬请期待...</div>
            </div>
        </div>
        <div class="upgrade-bottom">
            <div class="upgrade-bottom-desc">
                <div>版权所有：北京超图骏科信息技术有限公司<a class="a-first" href="https://beian.miit.gov.cn" target="_blank"
                        style="text-decoration:none;color:#999999;">京ICP备2023031958号&nbsp;&nbsp;</a></div>
                <img src="../../public/images/首页/police.png" alt="">&nbsp;&nbsp;&nbsp;&nbsp;<a
                    href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502051550" rel="noreferrer"
                    target="_blank">京公网安备11010502051550号</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Upgrade',
    data () {
        return {
            menuList: [
                { id: 1, title: '首页' },
                { id: 2, title: '关于骏科' },
                { id: 3, title: '产品中心' },
                { id: 4, title: '解决方案' },
                { id: 5, title: '场景体验' },
                { id: 6, title: '联系我们' }
            ],
            activeId: 1
        }
    },
    mounted () {
        var height = window.innerHeight
        console.log('height', height);
        if (height > 1500) {
            this.$refs.upgradeMainRef.style.height = height - 60 + 'px'
        } else {

            this.$refs.upgradeMainRef.style.height = height - 110 + 'px'
        }
        console.log('this.$refs.upgradeMainRef.height', this.$refs.upgradeMainRef);
    },
    methods: {
        munuClick (data) {
            console.log('data', data);
            this.activeId = data.id
        }
    }
}
</script>

<style scoped lang="scss">
@import '../themes/main.scss'
</style>
