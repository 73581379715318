<!--
 * @Author: RSDonkey fidslifeintech@gmail.com
 * @Date: 2022-11-18 09:17:34
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-20 11:25:12
 * @FilePath: \骏科官网\src\components\JunkeFooter.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <footer class="jk-footer" style="background-image:url('/images/首页/di.png')">
        <div class="jk-footer-des">
            <div class="jk-footer-des-col contact">
                <img class="jk-footer-icon" src="/images/首页/u338.png" alt="" @click="footerIconClicked()" />
                <p class="p-text">邮编：100015</p>
                <p class="p-text">总机：+86-10-59896655</p>
                <p class="p-text">传真：+86-10-59896666</p>
                <p class="p-text">技术支持热线：400-8900-866</p>
                <p class="p-text">地址：北京市朝阳区酒仙桥北路甲10号院电子城<br>IT产业园107号楼3层</p>
                <div class="contact-share">
                    <div class="box-share">分享<span style="visibility: hidden;">享</span> </div>
                    <el-popover placement="top" trigger="hover">
                        <img src="/images/关于骏科/骏科微信二维码.jpg" />
                        <img slot="reference" class="contact-share-icon" src="/images/首页/u299.svg" />
                    </el-popover>
                    <el-popover placement="top" trigger="none">
                        <img slot="reference" class="contact-share-icon" src="/images/首页/u298.svg" />
                    </el-popover>
                    <el-popover placement="top" trigger="none">
                        <img slot="reference" class="contact-share-icon" src="/images/首页/u300.svg" />
                    </el-popover>
                </div>
            </div>
            <div class="jk-footer-des-col links">
                <div class="links-col links-col-box" v-for="(linkCol, i) in linksData" :key="i">
                    <p class="links-col-title links-col-title1">{{ linkCol.colName }}</p>
                    <ul class="link-col-list">
                        <li class="links-col-item" v-for="(linkItem, i) in linkCol.children" :key="i">
                            <a :href="linkItem.href" target="__blank" class="a-text">{{ linkItem.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <contact-us-img></contact-us-img>
        <div class="jk-footer-bottom jk-footer-bottom1">
            <div>版权所有：北京超图骏科信息技术有限公司&nbsp;&nbsp;<a href="https://beian.miit.gov.cn" target="_blank"
                    style="text-decoration:none;color:#999999;">京ICP备2023031958号&nbsp;&nbsp;</a><span><img
                        src="../../public/images/首页/police.png" alt="" class="police_img"></span><a class="police"
                    href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502051550" rel="noreferrer"
                    target="_blank">京公网安备11010502051550号</a></div>
        </div>
    </footer>
</template>

<script>
import ContactUsImg from './ContactUsImg.vue';
export default {
    name: 'JunkeFooter',
    components: {
        ContactUsImg
    },
    data () {
        return {
            linksData: [
                {
                    colName: '关于骏科',
                    children: [
                        {
                            name: '公司简介',
                            href: '/about',
                        },
                        // {
                        //     name: '公司资质',
                        //     href: '/about',
                        // },
                        {
                            name: '技术实力',
                            href: '/about',
                        },
                        {
                            name: '公司荣誉',
                            href: '/about',
                        },

                        // {
                        //     name: '综合实力',
                        //     href: '/about',
                        // },
                    ]
                },
                {
                    colName: '产品中心',
                    children: [
                        {
                            name: 'MGS Server',
                            href: '/center/mgsserver',
                        },
                        {
                            name: 'MGS DMS',
                            href: '/center/mgsdms',
                        },
                        {
                            name: '电子沙盘',
                            href: '/center/digitalsandbox',
                        },
                        {
                            name: '数据产品',
                            href: '/center/dataproducts',
                        },
                        {
                            name: 'SuperMap GIS',
                            href: 'https://www.supermap.com/zh-cn/supermap/',
                        },
                    ]
                },
                {
                    colName: '解决方案',
                    children: [
                        {
                            name: '孪生战场环境保障',
                            href: '/solutions/envprobf',
                        },
                        {
                            name: '模拟仿真训练导调',
                            href: '/solutions/simtrain',
                        },
                        {
                            name: '地理空间智能情报',
                            href: '/solutions/spaintel',
                        },
                        {
                            name: '军民融合服务保障',
                            href: '/solutions/armcivinte',
                        },
                    ]
                },
                {
                    colName: '场景体验',
                    children: [
                        {
                            name: '场景演示',
                            href: '/sceneinspect',
                        },
                        {
                            name: '功能体验',
                            href: '/sceneinspect',
                        },
                    ]
                },
                {
                    colName: '联系我们',
                    children: [
                        {
                            name: '联系我们',
                            href: '/contactus',
                        },
                        {
                            name: '加入骏科',
                            href: '/contactus',
                        },
                    ]
                },
            ]
        }
    },
    methods: {
        footerIconClicked () {
            if (this.$route.name != 'home') {
                this.$router.push('/home')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/style/common.scss');
@import '@/assets/style/mixins.scss';

.jk-footer {
    width: 100%;
    padding-top: 150px;
    margin: 0;
    background-color: #2B2B2B;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #999999;
    position: relative;
    font-family: Microsoft YaHei;

    &-icon {
        cursor: pointer;
        position: absolute;
        transform: translate(0, -150%);
        width: 190px;
        height: 70px;
        display: block;
    }

    &-des {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;

        &-col {
            display: inline-block;
        }

        .contact {
            font-size: 14px;

            &-share {
                display: flex;
                vertical-align: middle;
                align-items: flex-end;

                div {
                    /* width: 50px; */
                    height: 20px;
                    color: white;
                    display: inline-block;
                    text-align: center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    line-height: 20px;
                }

                &-icon {
                    margin-left: 10px;
                    margin-right: 10px;
                    height: 20px;
                    width: 23px;
                }
            }
        }

        .links {
            @media screen and (min-width: 600px) {
                /* margin-left: 80px; */
            }

            &-col {
                // display: inline-block;
                float: left;
                margin-left: 20px;
                margin-right: 20px;
                font-size: 14px;

                // text-align: center;
                ul {
                    list-style-type: none;
                    // text-align: center;
                    padding-left: 0;
                }

                &-title {
                    color: white;
                    font-size: 18px;
                }

                &-item {
                    margin-top: 10px;

                    a:link,
                    a:visited {
                        color: #999999;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    hr {
        height: 2px;
        border-width: 0;
        color: gray;
        background-color: gray
    }

    &-bottom {
        font-size: 12px;
        height: 50px;
        background-color: #2d2d2d;
        text-align: center;
        line-height: 50px;
    }
}

.p-text {
    font-size: 14px;
    margin-bottom: 8px;
}

.box-share {
    width: 70px !important;
    height: 30px !important;
    font-size: 14px !important;
    line-height: 28px !important;
    margin-right: 24px !important;
    margin-top: 16px !important;
    background-image: url("../../public/images/首页/label.png");
}

.links-col-box {
    height: 200px !important;
}

.links-col-title1 {
    font-size: 18px;
}

.a-text {
    font-size: 14px;
}

.jk-footer-bottom1 {
    height: 50px;
    font-size: 14px;
    line-height: 50px;
}

.img-call {
    width: 42px;
    height: 53px;
    cursor: pointer;

}

.police {
    color: rgb(153, 153, 153);
}

.police_img {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
    margin-right: 4px;
    margin-left: 5px;
}
</style>