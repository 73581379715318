<!--
 * @Author: RSDonkey fidslifeintech@gmail.com
 * @Date: 2022-11-18 09:17:34
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-20 15:11:44
 * @FilePath: \trunk\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <Upgrade></Upgrade>
    </div>
</template>

<script>
// import DevicePixelRatio from "./utils/devicePixelRatio"
import Upgrade from '@/views/upgrade.vue'
export default {
    name: 'App',
    components: { Upgrade },
    data: () => ({
        //
    }),
    created () {
        // new DevicePixelRatio().init()
    }
};
</script>
<style lang="scss" scoped>
@import url('@/assets/style/common.scss');
@import '@/assets/style/mixins.scss';
</style>
