<!--
 * @Author: RSDonkey fidslifeintech@gmail.com
 * @Date: 2022-10-21 10:12:11
 * @LastEditors: guojiawen
 * @LastEditTime: 2023-10-27 09:34:54
 * @FilePath: \骏科官网\src\components\JunkeNaviBar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

    <header>
        <div class="nav-bar">
            <div class="nav-bar-logo"><img src="/images/首页/u338.png" @click="iconClicked" /></div>
            <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" :unique-opened="true" menu-trigger="click">
                <el-menu-item index="/home">首页</el-menu-item>
                <el-menu-item index="/about">关于骏科</el-menu-item>
                <el-submenu index="/productCenter">
                    <template slot="title">产品中心</template>
                    <el-menu-item index="/center/mgsserver">MGS Server</el-menu-item>
                    <el-menu-item index="/center/mgsdms">MGS DMS</el-menu-item>
                    <el-menu-item index="/center/digitalsandbox">电子沙盘</el-menu-item>
                    <el-menu-item index="/center/dataproducts">数据产品</el-menu-item>
                    <!-- <el-menu-item index="3-5">SuperMap GIS</el-menu-item> -->
                    <el-menu-item index="3-5">
                        <a href="https://www.supermap.com/zh-cn/supermap/" target="_self">SuperMap GIS</a>
                    </el-menu-item>
                </el-submenu>
                <!-- <el-submenu index="4">解决方案
                    <template slot="title">解决方案</template>
                    <el-menu-item index="4-1">解决方案</el-menu-item>
                    <el-menu-item index="4-2">模拟训练导调控制</el-menu-item>
                    <el-menu-item index="4-3">地理空间智能情报</el-menu-item>
                    <el-menu-item index="4-4">军民融合服务保障</el-menu-item>
                </el-submenu> -->
                <el-menu-item index="/solutions">解决方案</el-menu-item>
                <el-menu-item index="/sceneinspect">场景体验</el-menu-item>
                <el-menu-item index="/contactus">联系我们</el-menu-item>
                <!-- <div id="userInfoSec">
                    <img id="ringImg" src="/images/首页/u327.png" @click="ringClicked" />
                    <div id="ringMarker" @click="ringClicked" v-if="unreadMessage !== 0">{{ unreadMessage }}</div>
                    <div id="userAvatar">
                        <el-avatar :size="30" :src="userInfo.avatarUrl"></el-avatar>
                        <br />
                        <div id="userName">{{ userInfo.userName }}</div>
                        <img id="userMore" src="/images/首页/u335.svg" />
                    </div>
                </div> -->
            </el-menu>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'JunkeNaviBar',
        data() {
            return {
                activeIndex: '',
                unreadMessage: 2,
                userInfo: {
                    avatarUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                    userName: 'RSDonkey'
                },
            }
        },
        created() {
            // this.activeIndex = window.sessionStorage.getItem('activeIndex')

        },
        watch: {
            '$route.path': {
                handler(value) {
                    this.activeIndex = value
                },
                immediate: true
            }
        },
        methods: {
            iconClicked() {
                if (this.$route.name != 'home') {
                    this.$router.push('/home')
                    this.activeIndex = 'Home'
                    document.body.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    })
                }
            },
            // 通知图标被点击的回调
            ringClicked() {
                if (this.$route.name != 'home') {
                    this.$router.push('/home')
                    this.activeIndex = '1'
                }
            },
            handleSelect(key) {
                if (key !== '3-5') {
                    if (this.$route.name !== key) {
                        this.$router.push(key)
                    }

                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    $color: #ffffff;
    $menu-bg-color: #343536;

    header {
        width: 100%;
        padding: 0;
        font-family: Microsoft Yahei;
        position: absolute;
        top: 0;
        z-index: 100;
        background-color: #202020;


        .nav-bar {
            width: 1000px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            background-color: #202020;
            height: 60px;

            .nav-bar-logo {
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    height: 48px;
                }
            }
        }
    }


    ::v-deep .el-menu {
        border: 0;
        padding: 0;
        height: 80px;
        background: transparent;
        font-size: 18px;

        .el-menu-item,
        .el-menu-item .is-active,
        .el-submenu>.el-submenu__title {
            color: $color;
            font-size: 18px;
            border: 0;
            height: 60px;
            width: 120px;
            text-align: center;

        }

        .el-menu-item {
            background-color: #202020 !important;
        }

        .el-menu-item,
        .el-submenu {
            &.is-active {
                background-color: #343536 !important;
                color: $color !important;
            }

        }

        .el-menu-item:not(.is-disabled):hover,
        .el-submenu .el-submenu__title:hover {
            background: $menu-bg-color !important;
            color: $color !important;
        }

        .el-submenu:hover .el-submenu__title {}

        .el-submenu.is-active .el-submenu__title {
            border-color: transparent;
            color: $color;
        }

        .el-submenu.is-opened {
            background-color: #343536;
        }
    }


    /* User Info Sec */
    #userInfoSec {
        display: flex;
        position: relative;
        left: 10%;
    }

    #ringImg {
        position: relative;
        width: 13px;
        height: 16px;
        top: 50%;
        transform: translateY(-50%)
    }

    #ringImg:hover {
        cursor: pointer;
    }

    #ringMarker {
        /* position */
        position: relative;
        left: -3%;
        top: 25%;
        /* size */
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        /* fonts */
        font-size: 6px;
        color: white;
        text-align: center;
    }

    #ringMarker:hover {
        cursor: pointer;
    }

    #userAvatar {
        position: relative;
        display: flex;
        left: 10%;
        align-content: center;
    }

    #userAvatar .el-avatar {
        position: relative;
        top: 50%;
        transform: translateY(-50%)
    }

    #userName {
        position: relative;
        left: 5%;
        top: 30%;
        color: #909090;
        vertical-align: middle;
        /* transform: translateY(-50%) */
    }

    #userMore {
        width: 13px;
        height: 6px;
        position: relative;
        top: 45%;
        left: 10%;
    }
</style>
<style lang="scss">
    $color: #999999;
    $active-color: #fff;
    $menu-bg-color: #343536;

    .el-menu.el-menu--popup {
        background-color: #2d2d2d;
        opacity: 0.9;
        color: $color;

        .el-menu-item,
        .el-submenu__title {
            background: transparent !important;
        }

        .el-menu-item.is-active,
        .el-submenu.is-active>.el-submenu__title {
            color: $active-color !important;
        }

        .el-menu-item:not(.is-disabled):hover {
            color: $active-color;
            background-color: $menu-bg-color;
        }
    }

    /* .el-menu-item:not(.is-disabled):hover {
        background-color: #343536 !important;
    }

    .el-menu .el-submenu .el-submenu__title:hover {
        background-color: #343536 !important;
    } */

    a {
        text-decoration: none;
        color: #909297;
        padding: 0px;
    }

    a:hover {
        color: #fff;
    }

    /* .activeMenu {

        .el-menu .el-menu-item:not(.is-disabled):focus,
        .el-menu-item:not(.is-disabled):hover {
            background-color: red !important;
        }
    } */
    /* ::v-deep .el-menu {
        .el-menu-item .is-active {
            background-color: #343536 !important;
        }
    } */
</style>