/*
 * @Author: RSDonkey fidslifeintech@gmail.com
 * @Date: 2022-10-21 10:04:47
 * @LastEditors: guojiawen
 * @LastEditTime: 2023-10-13 08:53:53
 * @FilePath: \骏科官网\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
// 屏幕自适应插件
import 'lib-flexible'
// import VueLazyload from 'vue-lazyload'

import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// Vue.use(VueLazyload, {
//   lazyComponent: true
// });
Vue.use(ElementUI);
// Vue.use(vuetify);
router.afterEach((to,from,next)=>{
    window.scrollTo(0,0);
})
new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
