<!--
 * @Author: RSDonkey fidslifeintech@gmail.com
 * @Date: 2022-10-24 16:51:05
 * @LastEditors: guojiawen
 * @LastEditTime: 2023-10-26 10:12:16
 * @FilePath: \骏科官网\src\components\ContactUsImg.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="contactus">
        <div class="back-top" v-if="btnFlag" @click="backTop"></div>
        <img class="img-call" src="/images/首页/u2491.png" @click="clicked()" @mouseenter="expand = !expand" @mouseleave="expand = !expand" />

        <!-- <v-menu top open-on-hover>
            <template v-slot:activator="{ on, attrs }">
                <img class="contactus-icon" src="/images/首页/u249.png" @click="clicked()" v-bind="attrs" v-on="on" />
            </template>
            
            <div class="contactus-popover" style="background-image: url('/images/页头页脚/矩形 1.png')">
                <img class="contactus-popover-icon" src="/images/页头页脚/QQ 拷贝.png" alt="">
                <img class="contactus-popover-icon" src="/images/页头页脚/weixin.png" alt="">
            </div>
        </v-menu> -->
    </div>
</template>

<script>
export default {
    name: 'ContactUsImg',
    data() {
        return {
            expand: false,
            btnFlag: false,
            //记录屏幕滑动的值
            scrollTop: 0,
            items: [
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me 2' },
            ],
        }
    },
    mounted() {
        let that = this
        window.addEventListener('scroll', that.scrollToTop, true)
    },
    destroyed: function () {
        window.removeEventListener('scroll', this.scrollToTop)
    },
    methods: {
        clicked() {
            this.$router.push('/contactus')
            document.body.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        },
        //返回顶部
        backTop() {
            const that = this
            //使用定时器设置滑动过渡效果
            let timer = setInterval(() => {
                let ispeed = Math.floor(-that.scrollTop / 5)
                document.documentElement.scrollTop = document.body.scrollTop =
                    that.scrollTop + ispeed
                if (that.scrollTop === 0) {
                    clearInterval(timer)
                }
            }, 16)
        },
        // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
        scrollToTop() {
            const that = this
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            that.scrollTop = scrollTop
            if (that.scrollTop > 10) {
                that.btnFlag = true
            } else {
                that.btnFlag = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.contactus {
    position: fixed;
    width: 59px;
    height: 75px;
    right: 10px;
    bottom: 7%;
    z-index: 100;

    &-popover {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;
        background-position: center;
        width: 59px;
        height: 200px;

        &-icon {
            display: block;
            width: 50px;
            height: 50px;
        }
    }
}

img {
    width: 42px;
    height: 53px;
}

.back-top {
    width: 46px;
    height: 46px;
    position: relative;
    right: 5px;
    bottom: 20px;
    background-image: url(../../public/images/首页/BackTop.png);
    cursor: pointer;
    background-size: 100% 100%;
}
</style>