/*
 * @Author: RSDonkey fidslifeintech@gmail.com
 * @Date: 2022-10-24 09:12:21
 * @LastEditors: guojiawen
 * @LastEditTime: 2023-10-20 09:39:22
 * @FilePath: \骏科官网\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import PageTmp from '../components/PageTmp.vue'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'pages',
        component: PageTmp,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import('../views/HomePage.vue')
            },
            {
                path: 'about',
                name: 'About',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/AboutJunke.vue')
            },
            // products cneter submenu
            {
                path: 'center/:id',
                name: 'ProductCenter',
                component: () => import('../views/ProductCenter/index.vue'),
            },

            // solution submenu
            {
                // path: 'solutions/:id',
                path: 'solutions',
                name: 'SolutionCenter',
                component: () => import('../views/Solutions/index.vue'),
            },

            // others
            {
                path: 'sceneinspect',
                name: 'Sceneinspect',
                component: () => import('../views/SceneInspect.vue')
            },
            {
                path: 'contactus',
                name: 'Contactus',
                component: () => import('../views/ContactUs.vue')
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,//'/mobile/'
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        // vue2.0  x  y  控制
        // vue3.0  left  top 控制
        return { left: 0, top: 0 }
    }
})

export default router
