<!--
 * @Author: RSDonkey fidslifeintech@gmail.com
 * @Date: 2022-10-24 15:51:45
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-20 11:25:45
 * @FilePath: \junkepage\src\components\PageTmp.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <junke-navi-bar></junke-navi-bar>
        <router-view />
        <junke-footer></junke-footer>
    </div>
</template>

<script>
import JunkeFooter from './JunkeFooter.vue'
import JunkeNaviBar from './JunkeNaviBar.vue'
export default {
    name: 'PageTmp',
    components: {
        JunkeNaviBar,
        JunkeFooter,
    }
}
</script>

<style scoped></style>